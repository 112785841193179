<template>
  <Head>
    <title>О девелопере {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Группа Компаний «Основа» — девелоперская и инвестиционная компания, образованная в 2016 году, реализующая проект жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве."
    />
    <meta property="og:title" :content="'О девелопере ' + $titleEnd" />
    <meta
      property="og:description"
      content="Группа Компаний «Основа» — девелоперская и инвестиционная компания, образованная в 2016 году, реализующая проект жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве."
    />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'О девелопере' }]"
      />
      <h1 class="page-title h2">О девелопере</h1>
      <div class="page-content">
        <div class="row">
          <div class="col-xl-7 col-lg-8">
            <div class="content">
              <p>
                <img
                  loading="lazy"
                  src="@i/company.svg"
                  width="189"
                  height="56"
                  alt="Группа компаний Основа"
                />
              </p>
              <p>
                Группа Компаний &laquo;Основа&raquo;&nbsp;&mdash; девелоперская
                и&nbsp;инвестиционная компания, образованная в&nbsp;2016 году
                бывшими акционерами ГК&nbsp;&laquo;Мортон&raquo; после успешной
                продажи акций &laquo;Мортон&raquo; инвестору.
              </p>
              <p>
                Сегодня ГК&nbsp;&laquo;Основа&raquo; обладает
                диверсифицированной структурой и&nbsp;развивает четыре основные
                направления деятельности: девелопмент в&nbsp;формате
                &laquo;умный дом&raquo;; управление объектами коммерческой
                недвижимости; инвестиции в&nbsp;развитие семейной досуговой
                инфраструктуры; инвестиции в&nbsp;высокотехнологичные проекты
                и&nbsp;инновации.
              </p>
              <p>
                В&nbsp;девелоперском портфеле Компании на&nbsp;разных стадиях
                реализации сегодня находятся 15&nbsp;жилых проектов
                в&nbsp;столичном регионе площадью более
                1,5&nbsp;млн&nbsp;м&sup2;, а&nbsp;также один реализованный
                проект в&nbsp;Чехии.
              </p>
              <p>
                В&nbsp;столичном регионе Компания сосредоточилась
                на&nbsp;создании ярких жилых комплексов повышенной комфортности
                с&nbsp;авторской архитектурой, эксклюзивными общественными
                пространствами и&nbsp;благоустроенной придомовой территорией
                с&nbsp;возможностями для круглогодичного досуга и&nbsp;отдыха.
              </p>
              <p>
                В&nbsp;управлении ГК&nbsp;&laquo;Основа&raquo; находится более
                130&nbsp;тыс&nbsp;м&sup2;&nbsp;коммерческой недвижимости,
                включая 60&nbsp;тыс&nbsp;м&sup2;&nbsp;торговых площадей
                в&nbsp;ТЦ и&nbsp;в&nbsp;формате стрит-ритейл,
                40&nbsp;тыс&nbsp;м&sup2;&nbsp;гостиничной недвижимости,
                а&nbsp;также IT-Технопарк &laquo;Физтехпарк&raquo; площадью
                30&nbsp;тыс&nbsp;м&sup2;.
              </p>
              <p>
                Одним из&nbsp;приоритетных направлений деятельности
                ГК&nbsp;&laquo;Основа&raquo; является развитие направления
                семейной досуговой инфраструктуры. Компания открывает городские
                курорты водных развлечений под брендом TERMOLAND, а&nbsp;также
                парки квестов и&nbsp;кинетических аттракционов Клаустрофобия
                и&nbsp;Challenge Park.
              </p>
              <p>
                Также ГК&nbsp;&laquo;Основа&raquo; активно развивает
                и&nbsp;внедряет передовые технологии. Компания инвестирует
                в&nbsp;самый широкий спектр технологий: от&nbsp;блокчейн
                и&nbsp;проектов в&nbsp;области искусственного интеллекта,
                до&nbsp;транспортных систем нового поколения, инновационных
                технологий в&nbsp;области строительных материалов, водоочистки
                и&nbsp;технологии &laquo;умный дом&raquo;. В&nbsp;рамках этого
                направления Компания строит платформенные решения в&nbsp;области
                систем управления жилыми домами и&nbsp;процессом строительства.
              </p>
              <p>
                Кроме того, Компания предлагает юридическим лицам услуги
                по&nbsp;бухгалтерскому обслуживанию, кадровому
                администрированию, юридическому сопровождению и&nbsp;организации
                делопроизводства.
              </p>
            </div>
          </div>
          <div class="col-xl-5 col-lg-4">
            <div class="sidebar">
              <div class="sidebar__title h6">Контактная информация</div>
              <div class="sidebar-item">
                <p class="sidebar-item__title">Руководство</p>
                <p>
                  Ручьев Александр Валерьевич<br />
                  Храмов Егор Николевич<br />
                  Колченко Олег Николаевич
                </p>
              </div>
              <div class="sidebar-item">
                <p class="sidebar-item__title">Сайт девелопера</p>
                <p>
                  <a
                    href="https://gk-osnova.ru/directions/development"
                    target="_blank"
                    >gk-osnova.ru<Icon icon="external"
                  /></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
  },
};
</script>

<style scoped>
@media (min-width: 1280px) {
  .content {
    font-size: 20px;
  }
}

.content {
  padding: 60px 64px;
  background: #f8f8f8;
}

.sidebar {
  position: sticky;
  top: -40px;
  padding-top: 156px;
  padding-bottom: 35px;
  padding-left: 10px;
}

.sidebar-item p {
  margin: 0 0 4px;
}

.sidebar-item {
  margin-bottom: 25px;
}

.sidebar-item a {
  color: inherit;
}

body.-notouch .sidebar-item a:hover {
  color: #205640;
}

.sidebar-item__title {
  color: #9c9b9b;
}
@media (max-width: 991px) {
  .content {
    padding: 30px;
  }

  .sidebar {
    padding: 50px 0 0;
  }

  .sidebar-item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .content {
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
</style>
